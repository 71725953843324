
  import { defineComponent } from "vue";
  import CreateP2PExchangeForm from "@/views/currency/constituent/CreateP2PExchangeForm.vue";
  
  export default defineComponent({
    name: "CreateP2PExchange",
    components: {
        CreateP2PExchangeForm,
    },
    setup() {
      return {};
    },
  });
  