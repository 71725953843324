import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, Suspense as _Suspense, createBlock as _createBlock, vModelText as _vModelText, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "row justify-content-center my-5" }
const _hoisted_2 = { class: "card col-12 col-md-6 col-lg-4" }
const _hoisted_3 = { class: "card-body" }
const _hoisted_4 = { class: "mb-3" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "mb-3" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "mb-3" }
const _hoisted_9 = { class: "mb-3" }
const _hoisted_10 = { class: "mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputWrapper = _resolveComponent("InputWrapper")!
  const _component_DualRingLoader = _resolveComponent("DualRingLoader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DualRingLoader, { loading: _ctx.loading }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("form", {
              class: "",
              onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.Submit && _ctx.Submit(...args)), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", null, [
                  _cache[10] || (_cache[10] = _createElementVNode("label", {
                    for: "base_currency",
                    class: "form-label"
                  }, "Base Currency", -1)),
                  (_openBlock(), _createBlock(_Suspense, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_InputWrapper, {
                        errors: _ctx.formError.base_currency
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createElementVNode("select", {
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.base_currency) = $event)),
                            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('input', _ctx.formData.base_currency))),
                            class: "form-select form-select-lg",
                            "aria-label": "",
                            required: "",
                            id: "base_currency"
                          }, [
                            _cache[8] || (_cache[8] = _createElementVNode("option", {
                              disabled: "",
                              selected: "",
                              value: null
                            }, "Select Currency", -1)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredBaseCurrencies, (item, index) => {
                              return (_openBlock(), _createElementBlock("option", {
                                value: item.id,
                                key: index
                              }, _toDisplayString(item.name), 9, _hoisted_5))
                            }), 128))
                          ], 544), [
                            [_vModelSelect, _ctx.formData.base_currency]
                          ])
                        ]),
                        _: 1
                      }, 8, ["errors"])
                    ]),
                    fallback: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode("Loading currency...")
                    ])),
                    _: 1
                  }))
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", null, [
                  _cache[13] || (_cache[13] = _createElementVNode("label", {
                    for: "target_currency",
                    class: "form-label"
                  }, "Target Currency", -1)),
                  (_openBlock(), _createBlock(_Suspense, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_InputWrapper, {
                        errors: _ctx.formError.target_currency
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createElementVNode("select", {
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.target_currency) = $event)),
                            onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('input', _ctx.formData.target_currency))),
                            class: "form-select form-select-lg",
                            "aria-label": "",
                            required: "",
                            id: "target_currency"
                          }, [
                            _cache[11] || (_cache[11] = _createElementVNode("option", {
                              disabled: "",
                              selected: "",
                              value: null
                            }, "Select Currency", -1)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredTargetCurrencies, (item, index) => {
                              return (_openBlock(), _createElementBlock("option", {
                                value: item.id,
                                key: index
                              }, _toDisplayString(item.name), 9, _hoisted_7))
                            }), 128))
                          ], 544), [
                            [_vModelSelect, _ctx.formData.target_currency]
                          ])
                        ]),
                        _: 1
                      }, 8, ["errors"])
                    ]),
                    fallback: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode("Loading currency...")
                    ])),
                    _: 1
                  }))
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", null, [
                  _cache[14] || (_cache[14] = _createElementVNode("label", {
                    for: "exchange_rate",
                    class: "form-label"
                  }, "Exchange Rate", -1)),
                  _createVNode(_component_InputWrapper, {
                    errors: _ctx.formError.exchange_rate
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.exchange_rate) = $event)),
                        type: "number",
                        placeholder: "0.00",
                        class: "form-control",
                        id: "exchange_rate",
                        step: "any",
                        required: ""
                      }, null, 512), [
                        [
                          _vModelText,
                          _ctx.formData.exchange_rate,
                          void 0,
                          { number: true }
                        ]
                      ])
                    ]),
                    _: 1
                  }, 8, ["errors"])
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", null, [
                  _cache[15] || (_cache[15] = _createElementVNode("label", {
                    for: "min_exchange_amount",
                    class: "form-label"
                  }, "Min. Exchange Amount", -1)),
                  _createVNode(_component_InputWrapper, {
                    errors: _ctx.formError.min_exchange_amount
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.min_exchange_amount) = $event)),
                        type: "number",
                        placeholder: "0.00",
                        class: "form-control",
                        id: "min_exchange_amount",
                        required: ""
                      }, null, 512), [
                        [
                          _vModelText,
                          _ctx.formData.min_exchange_amount,
                          void 0,
                          { number: true }
                        ]
                      ])
                    ]),
                    _: 1
                  }, 8, ["errors"])
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", null, [
                  _cache[16] || (_cache[16] = _createElementVNode("label", {
                    for: "max_exchange_amount",
                    class: "form-label"
                  }, "Max. Exchange Amount", -1)),
                  _createVNode(_component_InputWrapper, {
                    errors: _ctx.formError.max_exchange_amount
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.max_exchange_amount) = $event)),
                        type: "number",
                        placeholder: "0.00",
                        class: "form-control",
                        id: "max_exchange_amount",
                        required: ""
                      }, null, 512), [
                        [
                          _vModelText,
                          _ctx.formData.max_exchange_amount,
                          void 0,
                          { number: true }
                        ]
                      ])
                    ]),
                    _: 1
                  }, 8, ["errors"])
                ])
              ]),
              _cache[17] || (_cache[17] = _createElementVNode("div", { class: "text-center" }, [
                _createElementVNode("button", { class: "btn btn-dark" }, "Create P2P Exchange")
              ], -1))
            ], 32)
          ])
        ]),
        _: 1
      }, 8, ["loading"])
    ])
  ]))
}