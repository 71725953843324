import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateP2PExchangeForm = _resolveComponent("CreateP2PExchangeForm")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "mb-5 text-center" }, [
      _createElementVNode("h1", null, "Create P2P Exchange"),
      _createElementVNode("div", null, [
        _createElementVNode("small", null, "Exchange rates added here are available for users to use when exchanging between their wallets")
      ])
    ], -1)),
    _createVNode(_component_CreateP2PExchangeForm)
  ]))
}