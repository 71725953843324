
  import { defineComponent, ref, onBeforeMount, computed } from "vue";
  import { apiPost } from "@/api";
  import { apiGet } from "@/api";
  import DualRingLoader from "@/components/loader/DualRingLoader.vue";
  import { Toast } from "@/utils/notify";
  import { ShowSuccess } from "@/utils/notify";
  import InputWrapper from "@/components/InputWrapper.vue";
  
  export default defineComponent({
    name: "CreateP2PExchangeForm",
    components: { InputWrapper, DualRingLoader },
    emits: ["save"],
    setup() {
    
     const currencies = ref([]);

      const formData = ref({
        exchange_rate: null,
        base_currency: null,
        target_currency: null,
        min_exchange_amount: null,
        max_exchange_amount: null,
        fee_fixed: 0,
        fee_percentage: 0
      });
      const formError = ref({});
      const loading = ref(false);


        // Computed Properties for Filtering Currencies
    const filteredBaseCurrencies = computed(() => {
      // Exclude the selected target currency from the base currency options
      return currencies.value
        ? currencies.value?.filter(
            (currency: any) => currency.id !== formData.value.target_currency
          )
        : [];
    });

    const filteredTargetCurrencies = computed(() => {
      // Exclude the selected base currency from the target currency options
      return currencies.value
        ? currencies.value?.filter(
            (currency: any) => currency.id !== formData.value.base_currency
          )
        : [];
    });


      async function Submit() {
        loading.value = true;
        formError.value = {};
        try {
          await apiPost(`/admin/currency-exchange`, formData.value);
          ShowSuccess("Created new currency exchange setting successfully", () => {
            formData.value = {
              exchange_rate: null,
              base_currency: null,
              target_currency: null,
              min_exchange_amount: null,
              max_exchange_amount: null,
              fee_percentage: 0,
              fee_fixed: 0
            };
          });
        } catch (e:any) {
          formError.value = e?.response?.data?.i ?? {};
          Toast.fire({
            icon: "warning",
            title: "Unable to create entry: " + e.message,
          }).then();
        }
        loading.value = false;
      }

      const RefreshData = async () => {
        loading.value = true;
        try {
          const response = await apiGet(
            `/misc/fiat-currencies`,
            (currencies.value as any)?.meta ?? {}
          );
          console.log(response.data)
          currencies.value = response.data.data.fiat;
        } catch (e:any) {
          Toast.fire({
            icon: "warning",
            title: "Unable to fetch data: " + e.message,
          }).then();
        }
        loading.value = false;
      };
  
      onBeforeMount(RefreshData);

      return {
        Submit,
        formData,
        formError,
        loading,
        currencies,
        filteredBaseCurrencies,
        filteredTargetCurrencies,
        RefreshData
      };
    },
  });
  